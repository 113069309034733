// import MediaTrigger from 'media-trigger';

const App = {
  // mediaTrigger: null,

  // initMediaTrigger() {
  //   this.mediaTrigger = new MediaTrigger({
  //     media: '(min-width: 992px)',
  //     entry: () => {
  //
  //     },
  //     exit: () => {
  //
  //     },
  //   });
  //
  //   this.mediaTrigger.init();
  // },

  async init() {
    // this.initMediaTrigger();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
